// Fallbacks taken from:
// http://melchoyce.github.io/fontstacks/examples/open-sans.html

// COLORS:
$justfix-black: #242323;
$justfix-white: #faf8f4;
$justfix-grey-dark: #676565;
$justfix-grey-light: #d4d5d0;

$justfix-green: #1aa551;
$justfix-pink: #ffa0c7;
$justfix-yellow: #ffba33;
$justfix-orange: #ff813a;
$justfix-blue: #5188ff;

$transparent: rgba(0, 0, 0, 0);

$justfix-grey-400: #9a9898;
$justfix-grey-50: #f2f2f2;
$justfix-grey-light-accent: #efe9dc;

$primary: $justfix-black;
$secondary: $justfix-white;
$secondary-accent: $justfix-grey-light-accent; // A slight variation on our secondary color to stand out against a background
$info: $justfix-black; // Color for logo and non-text elements
$link: $justfix-black; // Inline hyperlink color
$dark: $justfix-black; // Primary text color for light backgrounds
$grey-light: $justfix-white; // Secondary text color for dark backgrounds
$light: $justfix-grey-light; // Secondary background color for buttons (i.e. Cancel buttons)
$danger: $justfix-orange; // Color for error messages

// BUTTONS:
$button-padding-vertical: $spacing-07;
$button-padding-horizontal: $spacing-10;

// MESSAGES:
$message-body-padding: $spacing-09;
$message-body-border-width: 0px;

// CONTENT:
$title-color: $dark;
$subtitle-color: $dark;
$content-heading-color: $dark;
$body-color: $dark;

// FORMS:
$label-color: $dark;
$input-color: $dark;

// BOXES:
$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
