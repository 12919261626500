$norent-sticky-menu-height: 100px;

.jf-sticky-button-container {
  @include mobile {
    // Allow extra padding on the last child container to make room for sticky menu,
    // as well as a margin to make space between the menu and the footer:
    section:last-child {
      padding-bottom: $norent-sticky-menu-height;
    }
    margin-bottom: calc(#{$norent-sticky-menu-height} / 2);
  }
  .jf-sticky-button-menu {
    position: sticky;
    height: $norent-sticky-menu-height;
    top: calc(100% - #{$norent-sticky-menu-height});
    width: 100%;
    z-index: 1000;
    padding: 1rem;
  }
}

// We need to offset the position of whatever container appears after the sticky menu
// Otherwise, we will be left with empty space at the menu's original position
.jf-sticky-button-menu + .container {
  @include mobile {
    margin-top: calc(-#{$norent-sticky-menu-height} / 2);
  }
}

.jf-illustration-paper-airplanes,
.jf-illustration-fist-pump {
  width: 200px;
  height: 200px;
  @include mobile {
    width: 128px;
    height: 128px;
  }
}

.jf-illustration-paper-airplanes {
  // Illustration spacing offset according to design:
  margin-left: 3rem;
  figure {
    margin: 0;
  }
}
.jf-illustration-fist-pump {
  @include is-horizontal-center;
}

.jf-letter-counter {
  .hero-body {
    padding-top: 0;
    padding-bottom: 0;
    @include tablet {
      padding: 0;
    }
    .title {
      font-size: 4rem;
    }
  }
}

.jf-norent-checklist {
  .media {
    height: 5rem;
  }
}

.jf-collective-action-list {
  .media {
    border: none;
    .media-left figure.image {
      margin-left: 0;
    }
  }
  @include tablet {
    // Fixes bug on firefox where container extends
    // all the way to maximum width on desktop
    width: max-content;
    .media {
      @include is-horizontal-center;
    }
  }
}

.jf-norent-checklist,
.jf-collective-action-list {
  .media-content {
    align-self: center;
    // Fixes bug on Safari that made these content blocks scrollable
    display: table-cell;
  }
}

.jf-partner-logos {
  a figure.image:hover {
    opacity: 0.8;
    transform: scale(1.02);
  }
  @include tablet {
    figure.image {
      height: 160px;
      width: 160px;
    }
  }
}

.jf-letter-preview-container.hero-body {
  padding-bottom: 0;
  @include mobile {
    .message .message-body {
      padding: 1.5rem;
    }
  }

  .jf-letter-preview-fadeout {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45%;
    background: linear-gradient(
      0deg,
      $grey-lighter 50%,
      rgba(255, 255, 255, 0) 102.42%
    );
  }
}

.jf-how-it-works-container {
  .column {
    p:not(.title) {
      line-height: 1.35;
    }
    figure {
      margin-bottom: 1rem;
    }
    @include mobile {
      // Make divs in column line up side by side on mobile
      display: flex;
      figure {
        width: 64px;
        height: 64px;
        margin: 0.5rem 1.5rem 0 0;
      }
    }
  }
}

.jf-faqs,
.jf-faqs-preview {
  .jf-accordion-item {
    &:not(:last-child) {
      border-bottom: 0.3px solid #828282ee;
    }
  }
}

.jf-faqs {
  .hero-body {
    padding-top: 1rem;
    h5 {
      letter-spacing: 0.04rem;
    }
  }
}
